import Vue from '../../main.js';
/**
 * Declare Variable
 */
const serviceUrl = 'http://localhost:8077'
const scan = `${serviceUrl}/scan/`
/**
 * Export
 */
export default {
  async scan(data) {
    return Vue.http.get(scan);
  },
}