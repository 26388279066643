<template>
<div class="color" :class="temperatureColor">
</div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: ['ranges', 'temperature', 'commodity'],
  computed: {
    temperatureColor() {

      const comm = this.ranges.find(r => r.commodity == this.commodity)
      if (comm) {
        let maxValue = comm.greenRangeMaxValue
        let minValue = comm.greenRangeMinValue
        if (minValue <= this.temperature && maxValue >= this.temperature) {
          return 'green'
        }
        maxValue += comm.orangeRangeMaxValue
        minValue += comm.orangeRangeMinValue
        if (minValue <= this.temperature && maxValue >= this.temperature) {
          return 'orange'
        } else {
          return 'red'
        }
      } else {
        return ''
      }
    },
  }
}
</script>
