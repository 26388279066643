<template>
<v-row>
    <v-col cols="12" md="2">
        <v-subheader class="label-left">Product/Producto:</v-subheader>
    </v-col>
    <v-col cols="12" md="4">
        <v-select solo :items="list" :return-object="true" item-text="name" outline v-model="selected">
            <template v-slot:append-outer class="add-product">
                <v-btn class="add-product" fab dark color="primary" @click="add" small>
                    <v-icon dark>mdi-plus-circle</v-icon>
                </v-btn>
            </template>
        </v-select>
        <v-data-table :items="value" class="elevation-1" hide-default-actions hide-default-headers item-key="_temp" :headers="headers">
            <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
            </template>
            <template v-slot:[`item.actions`]="{ index}" class="justify-center layout px-0">
                <v-icon small class="mr-2" @click="deleteItem(index)" title="Edit/Editar">mdi-delete-sweep</v-icon>
            </template>
        </v-data-table>
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        list: Array,
        value: Array,
        onAdd: Function,
        onRemove: Function
    },
    data() {
        return {
            selected: null,
            data: [],
            headers: [{
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                },
            ],
        };
    },
    watch: {
        value: function (newVal, oldVal) {}
    },
    methods: {
        deleteItem(index) {
            if (confirm("Are you sure you want to delete this item?")) {
                const item = this.value.splice(index, 1);
                this.$emit("input", this.value);
                if (this.onRemove) {
                    this.onRemove(item[0]);
                }
            }
        },
        add() {
            if(this.selected == null)
            return
            const updated = this.value;
            const newVal = {
                name: this.selected.name,
                commodityID: this.selected.id,
                id: this.guid()
            };
            updated.push(newVal);
            this.$emit("input", updated);
            if (this.onAdd) {
                this.onAdd(newVal);
            }
        },
        guid() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
                c
            ) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
    }
};
</script>

<style>
.add-product {
    margin: 0;
    padding: 0;
    top: -12px;
}
</style>
