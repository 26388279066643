<template>
<div>
    <title-section>
        WAREHOUSE POLICIES / POLITICAS DEL ALMACEN
    </title-section>
    <v-row>
        <v-col cols="12" class="driver-instructions">
            <div class="text-xs-center subheading">Drivers can walk or wait in designated areas only. The drivers are responsible for leverage brake of their units<br />
                Los choferes pueden caminar o esperar en areas designadas solamente. Los choferes son responsables de apalancar freno de sus unidades </div>
        </v-col>
    </v-row>
    <v-divider />
</div>
</template>

<script>
import TitleSection from './TitleSection'
export default {
    name: 'warehousePolicies',
    components: {
        TitleSection
    }
}
</script>

<style scoped>
.driver-instructions {
    padding-top: 0px;
}

.title {
    color: #FAFAFA;
    padding: 5px;
}

.subheading {
    padding: 2px;
    font-size: 13px !important;
}
</style>
